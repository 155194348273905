import { useState, useMemo, useCallback, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ArrowRightAlt, Pending } from "@mui/icons-material";
import dayjs from "dayjs";

import { COLORS } from "../theme";

import PageSpinner from "./PageSpinner";
import FeaturedEventCard from "./FeaturedEventCard";
import EventCard from "./EventCard";
import { useRouter } from "next/router";
import { useSearchParams } from "next/navigation";
import { EventDoc } from "../services/event";

type Props = {
  events: EventDoc[],
  title: string,
  bgColor: string,
  locations: string[],
  ages: string[],
  initialSearchParams: {
    ageFrom?: number,
    ageTo?: number,
    location?: string,
    type?: string,
    date?: string,
  },
}

export default function EventSearch({
  events: initialEvents,
  title,
  bgColor,
  locations = [],
  ages = [],
  initialSearchParams = {},
}: Props) {
  const searchParams = useSearchParams();

  let initialAge =
    searchParams.get("ageFrom") 
      ? searchParams.get("ageTo") 
        ? `${searchParams.get("ageFrom")} - ${searchParams.get("ageTo")}`
        : `${searchParams.get("ageFrom")}+`
      : null;

  if (initialAge === null && initialSearchParams?.ageFrom) {
    initialAge = initialSearchParams.ageTo 
      ? `${initialSearchParams.ageFrom} - ${initialSearchParams.ageTo}`
      : `${initialSearchParams.ageFrom}+`;
  }

  const initialLocation = searchParams.get("location")
    ?? initialSearchParams?.location
    ?? null;
  let initialDate = searchParams.get("date")
    ? dayjs(searchParams.get("date"))
    : null;
  if (initialDate === null && initialSearchParams?.date) {
    initialDate = dayjs(initialSearchParams.date);
  }
  const iniitialEventType = searchParams.get("type")
    ?? initialSearchParams?.type
    ?? null;
  

  const [date, setDate] = useState(initialDate);
  const [location, setLocation] = useState(initialLocation);
  const [ageRange, setAgeRange] = useState(initialAge);
  const [eventTypeSearch, setEventTypeSearch] = useState(iniitialEventType);

  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  // const [eventTypeSearch, setEventTypeSearch] = useState(
  //   searchParams.get("type")
  // );

  const router = useRouter();

  const handleSearch = (e) => {
    e.preventDefault();
    const [ageFrom, ageTo] = ageRange 
      ? ageRange?.indexOf(" - ") > -1 
      //e.g. "18 - 30"
        ? ageRange.split(" - ") 
        //e.g  "18" or "18+"
        : [ageRange.replace('+', '').trim(), null]
      // or if no age range is selected
      : [undefined, undefined];

    const queries = {
      ...router.query,
      location,
      type: eventTypeSearch,
      ageFrom,
      ageTo,
      date:
        date && date.year()
          ? `${date.year()}-${date.month() + 1}-${date.date()}`
          : "",
    };

    if (!location) delete queries.location;
    if (!eventTypeSearch) delete queries.type;
    if (!ageFrom) delete queries.ageFrom;
    if (!ageTo) delete queries.ageTo;
    if (!date) delete queries.date;
    if (date && (!date.year() || !date.month() || !date.date()))
      delete queries.date;
    setIsSearching(true)
    console.log(queries)
    const params = new URLSearchParams(queries)
    router.push(`?${params.toString()}`, undefined, { shallow: true })
    fetch('/api/eventSearch?'+params.toString()).then(res => res.json())
    .then(data => {
      // console.log(data)
      setSearchResults(data.results)
    })
    .finally(() => {setIsSearching(false)})
  };

  const events = searchResults || initialEvents;

  return (
    <>
      <Box bgcolor={bgColor || COLORS.lightGrey} minHeight="auto">
        <Container>
          <Box>
            {events.length > 0 ? (
              <Typography
                variant={title ? "h4" : "h1"}
                sx={(theme) => ({
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontSize: "32px",
                  paddingTop: theme.spacing(4),
                  "& .event-span": {
                    color: theme.palette.primary.main,
                  },
                  [theme.breakpoints.down("xs")]: {
                    fontSize: 30,
                  },
                })}
              >
                {title || (
                  <>
                    <strong>Find your</strong> Perfect Dating Events
                  </>
                )}
              </Typography>
            ) : (
              <Box textAlign="center" pt={10}>
                <Typography variant="h6">
                  We currently have no events available...
                </Typography>
              </Box>
            )}
            <Box my={4}>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md>
                    <Autocomplete
                      fullWidth
                      options={locations}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, newValue) => {
                        setLocation(newValue);
                      }}
                      value={location}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    />
                  </Grid>

                  <Grid item xs={6} sm>
                    <TextField
                      fullWidth
                      label="Event Type"
                      select
                      variant="outlined"
                      defaultValue={eventTypeSearch}
                      value={eventTypeSearch ?? ""}
                      onChange={(e) => {
                        setEventTypeSearch(e.target.value);
                      }}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    >
                      <MenuItem value={""}>All</MenuItem>
                      <MenuItem value={"Speed"}>Speed Dating</MenuItem>
                      <MenuItem value={"Single"}>Singles Party</MenuItem>
                      <MenuItem value={"Christian"}>
                        Christian Speed Dating
                      </MenuItem>
                      <MenuItem value={"Gay"}>Gay Singles Party</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm>
                    <TextField
                      fullWidth
                      onChange={(e) => setAgeRange(e.target.value)}
                      label="Age"
                      select
                      variant="outlined"
                      value={ageRange ?? ""}
                      defaultValue=""
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                      })}
                    >
                      <MenuItem key={`ageRange_None`} value={""}>
                        <em>None</em>
                      </MenuItem>
                      {ages.map((range, i) => {
                        return (
                          <MenuItem key={`ageRange_${i}`} value={range}>
                            {range}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm>
                    <DatePicker
                      // auto
                      // disableToolbar
                      label="Date"
                      format="DD/MM/YYYY"
                      value={date}
                      onChange={(date) => setDate(date)}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.grey[200],
                        width: "100%",
                      })}
                      // clearable={true}
                    />
                  </Grid>
                  <Grid item>
                    <Box height="100%" display="flex" alignItems="center">
                      <Button
                        size={title ? "large" : "medium"}
                        fullWidth
                        variant="contained"
                        color="primary"
                        endIcon={isSearching ? <Pending /> : <ArrowRightAlt />} 
                        type="submit"
                        disabled={isSearching}
                      >
                        Search
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <Grid container spacing={2} sx={{ opacity: isSearching ? 0.5 : 1 }}>
              {events.length > 0 
                ? events.map((event, index) =>
                  event.featured ? (
                    <Grid item container key={event.id}>
                      <FeaturedEventCard {...event} />
                    </Grid>
                  ) : (
                    <Grid item key={`event_${index}`} xs={12} sm={6} md={4}>
                      <EventCard {...event} />
                    </Grid>
                  )
                )
              : <div style={{ width: "100%", textAlign: "center" }}>
                  No events were found matching your criteria.
                </div>
              }
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
